<template>
  <div class="modal" :class="{'is-active': show}">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="box">
        <h2 class="title is-4">Recuperar Contraseña</h2>
        <form @submit.prevent="sendPasswordReset">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="email"
                placeholder="Correo electrónico"
                v-model="resetEmail"
              />
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="button" class="button is-outlined" @click="close">Cerrar</button>
            </div>
            <div class="control">
              <button
                class="button"
                :class="{'is-loading': loading}"
              >
                Enviar enlace
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      resetEmail: '',
      loading: false
    }
  },
  methods: {
    close () {
      this.resetEmail = ''
      this.$emit('update:show', false)
    },
    sendPasswordReset () {
      if (this.resetEmail) {
        this.loading = true
        this.$store.dispatch('requestRecoveryPassword', { email: this.resetEmail })
          .then(() => {
            this.$emit('update:show', false)
            this.resetEmail = ''
          })
          .catch(error => {
            console.error('Error al enviar el enlace de recuperación:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
.modal-background {
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  max-width: 500px;
  margin: auto;
  background: #292A30;
  border-radius: 10px;
}

.box {
  background: transparent;
  box-shadow: none;
  padding: 40px;
}

.button {
  background: #CD3F27;
  border-color: #CD3F27;
  color: #fff;
}

.button:hover {
  background: #b33822;
  border-color: #b33822;
}

.button.is-outlined {
  background: transparent;
  border-color: #CD3F27;
  color: #CD3F27;
}

.button.is-outlined:hover {
  background: #CD3F27;
  color: #fff;
}

.input {
  border: 1px solid #374f6e;
}

.input:focus {
  border-color: #CD3F27;
  box-shadow: 0 0 5px #CD3F27;
}

h2.title {
  color: #fff;
}
</style>
