<template>
  <div class="hero is-fullheight" id="login">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered v-centered">
          <div class="column is-6 column-image">
            <figure class="avatar">
              <img class="image" src="@/assets/logo-GivU-blanco.svg">
            </figure>
          </div>
          <div class="column-line is-hidden-mobile">
          </div>
          <div class="column is-6">
            <div class="padding-section">
              <h1 class="title">Bienvenido</h1>
            </div>
            <div class="columns is-centered v-centered">
              <div class="column is-8">
                <div class="box">
                  <form
                    @submit.prevent="loginUser"
                  >
                    <div class="field">
                      <div class="control">
                        <input class="input"
                          type="email"
                          name="email"
                          placeholder="Correo electrónico"
                          autofocus=""
                          autocomplete="off"
                          v-model="email"
                          >
                      </div>
                    </div>
                      <div class="field">
                        <div class="control">
                          <input
                            class="input"
                            type="password"
                            placeholder="Contraseña"
                            name="password"
                            v-model="pass"
                          >
                        </div>
                      </div>
                    <button :class="{'is-loading': loading}" class="button is-block is-medium is-fullwidth">Ingresar</button>
                  </form>
                </div>
                <p class="has-text-grey">
                  <a @click.prevent="showResetPasswordModal = true" class="link">¿Olvidaste tu contraseña?</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ResetPasswordModal
      :show="showResetPasswordModal"
      @update:show="showResetPasswordModal = $event"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ResetPasswordModal from '@/components/admin/ResetPasswordModal.vue'

export default {
  name: 'Login',
  components: {
    ResetPasswordModal
  },
  data () {
    return {
      email: null,
      pass: null,
      loading: false,
      showResetPasswordModal: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication })
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['signin']),
    loginUser () {
      this.loading = true
      this.signin({ email: this.email, password: this.pass })
        .then(success => {
          if (success) {
            const redirect = this.$route.query.redirect || '/'
            this.$router.push(redirect)
          } else {
            this.loading = false
          }
        })
        .catch(error => {
          console.error('Error en el inicio de sesión:', error)
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
  #login {
    background-color: #292A30;
  }
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column-image {
    margin: auto;
    width: 50%;
  }
  .login {
    align-items: center;
    justify-content: center;
  }
  .box {
    background: transparent;
    box-shadow: none;
    padding: 40px;
  }
  .button {
    margin-top: 50px;
    background: #CD3F27;
    border-color: #CD3F27;
    color: #fff;
  }
  .button:hover {
    background: #b33822;
    border-color: #b33822;
  }
  .hero-body {
    padding: 0rem 1.5rem !important;
  }
  .vcenteded {
    display: flex;
    min-height: 100vh;
    align-items: center;
  }
  h1 {
    font-size: 22px;
    color: #fff;
  }
  p {
    font-size: 16px;
    color: #fff;
  }
  .column-line {
    border-right: 1px solid #374f6e;
    border-left: 1px solid #141d29;
  }
  .padding-section {
    margin: 20px 0;
  }
  .link, .link:focus, .link:active, .link:visited {
    color: #FFFFFF;
  }
</style>
